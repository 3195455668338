.section-materials-container-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    position: relative;
    height: 100%;
}

.grid-columns {
    padding: 25px 25px;
    margin: 25px 0;
    @include MQ(Lneg) {
        padding: 25px 0 0;
        margin: 25px 0 0;
    }
    .grid-columns-inner {        
        margin: 0 auto;
    }
    .grid-columns-inner-default {
        max-width: $XLS;
        width: col(12);
        display: flex;
        justify-content: space-between;
        @include MQ(Lneg) {
            flex-direction: column-reverse;
        }

        .grid-column-float-left {
            width: col(6);
            margin: 0 auto;              
            @include MQ(XLneg) {
                margin-left: 0;
                width: col(7);
            }
            @include MQ(Lneg) {
                width: col(12);
                margin: 0 auto;
            }   
        }
        .clear-spacer {
            width: col(4);
            @include MQ(MAXneg) {
                width: col(4.5);
            }
            @include MQ(Lneg) {
                width: col(12);
            }   
        }

        .column-heading {
            width: 100%;
            .column-heading-text {
                // max-width: 620px;        
                @include MQ(Lneg) {
                    padding: 0 25px;
                    max-width: 100%;
                }        
            }
            .column-heading-banner {
                width: 100%;
                picture {
                    img {
                        width: 100%;
                        display: block;
                        margin-left: auto;
                        padding-left: 7vw;
                        @include MQ(Lneg) {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}

.grid-columns-wide {
    .grid-columns-inner-color {
        padding: 35px 25px;
        &.color--green {
            $backgroundColor: $theme-green-lighten;
            // background: $theme-color-2;
            background: $backgroundColor;
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                width: 100%;
                height: 100%;
                background: $backgroundColor;
            }
        }
        &.color--gray {
            background: $theme-color-3;
            position: relative;
            margin-bottom: 25px;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: -100%;
                width: 100%;
                height: 100%;
                background: $theme-color-3;
            }
        }
    }

    .grid-columns-inner {
        width: col(12);
        padding: 35px 25px;
        display: flex;
        justify-content: space-between;

        @include MQ(Lneg) {
            flex-direction: column;
        }

        .grid-column-float-left {
            width: col(6);        
            margin: 0 auto;        
            @include MQ(XLneg) {
                margin-left: 0;
                width: col(7);
            }
            @include MQ(Lneg) {
                width: col(12);
            }
            // outline: 1px solid red;
        }
        .clear-spacer {
            width: col(4);
            // outline: 1px solid blue;
        }
    }

    .grid-column-float-left {
        .column-heading {
            padding-bottom: 25px;
            width: 85%;
            @include MQ(Lneg) {
                width: 100%;
            }
        }

        .column-list {
            display: flex;
            flex-flow: row wrap;
            &--white {
                .column-list-item {
                    border-color: $primary-green;
                }
            }
            &--primary {
                .column-list-item {
                    border-color: $theme-color-1;
                }
            }
            .column-list-item {
                width: 48%;
                flex-basis: 48%;
                border-width: 1px;
                border-style: solid;
                display: flex;
                align-items: center;
                height: 125px;
                margin-bottom: 15px;
                @include MQ(Mneg) {
                    width: 100%;
                    flex-basis: 100%;
                    margin-right: 0;
                }
                &:nth-of-type(odd) {
                    margin-right: 15px;
                    @include MQ(Mneg) {
                        margin-right: 0;
                    }
                }
                &:last-of-type {
                    margin-bottom: 0;
                }

            }
            .list-item-icon {
                padding-left: 25px;
                img {
                    transform: scale(.9);
                    display: block;
                    width: 60px;
                    height: 60px;
                }
            }
            .list-item-title {
                padding: 0 25px;
                margin-right: auto;
                text-align: left;
                @include MQ(Lneg) {
                    width: unset;                    
                }
                span {
                    display: block;
                    font-family: $Barlow;
                    font-weight: $fw-semibold;
                    color: $theme-color-1;
                    @include font-size(1.4);
                    @include MQ(MAXneg) {
                        padding-top: 5px;
                        font-size: 1.4rem;
                        line-height: 1.4;
                    }
                }
            }
        }
    }
}