$slider-button-size: 92px;
$slider-button-radius: $slider-button-size / 2;

%slider-play-small {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    
    .action-box {
        background: $theme-section-green;
        border-radius: $slider-button-radius;
        width: $slider-button-size;
        height: $slider-button-size;
        display: block;
        position: relative;
        transform: scale(1.15);
    
        @include MQ(Lneg) {
            transform: scale(1);
        }
        @include MQ(Sneg) {
            transform: scale(.7);
        }
    
        .action-box-inner {
            @include center;
            left: 50px;
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 21.5px 0 21.5px 37.2px;
            border-color: transparent transparent transparent $theme-color-white;
        }
    }
}

.header-slider-container {
    width: 100%;
    padding-top: 135px;
    padding-bottom: 100px;
    display: block;
    background-color: $theme-color-white;
    position: relative;
    @include MQ(XLneg) {
        padding-bottom: 0;
    }
}

.header-slider-container {
    width: 100%;
}

.header-slider {
    background-color: $theme-color-white;
    display: block;
    width: 100%;
    max-width: $XXL;
    margin: 0 auto;
    padding: 0 25px 0;

    @include MQ(XLneg) {
        padding: 0;
    }

    .slider-items-wrapper {
        .slider-item {
            &.slider-item-big {
                width: 100%;

                @include MQ(MAXneg) {
                    padding: 0 0 35px;
                }

                .item-big-picture {
                    display: flex;
                    align-items: center;

                    .item-big-picture-inner {
                        box-sizing: border-box;

                        img {
                            max-width: 100%;
                            width: 715px;
                            max-height: 100%;
                            height: 700px;
                            object-fit: cover;

                            @include MQ(XLneg) {
                                width: 100vw;
                                min-height: 500px;
                            }
                        }
                    }                    
                }

                .item-big-picture {
                    margin-left: 0;
                    transition: .3s all ease;

                    >img {
                        width: 715px;
                        height: 700px;
                    }
                }

                .item-big-info {
                    opacity: 1;
                    visibility: visible;
                    transition: .3s all ease;
                }
            }
        }
        .slider-item-big {
            position: relative;
            display: flex;
            align-items: center;
            justify-self: center;
            figure {
                z-index: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100%;

                @include MQ(XLneg) {
                    width: 100%;
                    flex-direction: column;
                }
            }

            .item-big-picture {
                order: 2;

                img {
                    width: 715px;
                    height: 700px;
                }

                @include MQ(XLneg) {
                    order: unset;
                }
            }

            .item-big-info {
                order: 1;
                padding: 10px;
                padding-bottom: 0;
                margin-left: 5vw;
                margin-right: -125px;
                z-index: 1;
                width: 100%;
                max-width: 720px;
                transition: .3s all ease;

                @include MQ(XLneg) {
                    margin: 0;
                    order: unset;
                    position: relative;
                    padding-top: 50px;
                }
                @include MQ(Lneg) {
                    padding: 65px 25px 10px;
                    width: auto;
                    max-width: unset;
                }
                
                .block-media-action {
                    @extend %slider-play-small;
                }

                .item-big-info-tag {
                    .item-big-info-tag-text {
                        color: $primary-purple;
                        text-transform: uppercase;
                        font-weight: $fw-bold;
                        @include font-size(3);

                        @include MQ(XLSneg) {
                            @include fluid-type($XL, $XLS, 24px, 30px);
                        }

                        @include MQ(XLneg) {
                            @include fluid-type($L, $XL, 21px, 24px);
                        }
                    }
                }

                .item-big-info-heading {
                    h1 {
                        color: $primary-green;
                        @include font-size(6);
                        @include fluid-type($XLS, $XXL, 50px, 60px);

                        @include MQ(XLSneg) {
                            @include font-size(5);
                        }

                        @include MQ(XLneg) {
                            @include fluid-type($L, $XL, 36px, 50px);
                            line-height: 1.4;
                        }
                    }
                }

                .item-big-info-subheading {
                    padding: 15px 0;

                    p {
                        @include font-size(3);
                        @include fluid-type($XLS, $XXL, 26px, 30px);
                        font-weight: $fw-semibold;

                        @include MQ(XLSneg) {
                            @include fluid-type($XL, $XLS, 22px, 26px);
                        }

                        @include MQ(XLneg) {
                            @include fluid-type($L, $XL, 20px, 22px);
                        }
                    }
                }

                .item-big-info-author {
                    display: flex;
                    align-items: center;
                    padding-top: 25px;
                    padding-bottom: 25px;
                    @include MQ(XLneg) {
                        padding-top: 0;
                        padding-bottom: 10px;
                    }

                    .item-big-info-author-multiple {
                        display: flex;
                        align-items: center;
                    }

                    .item-big-info-author__avatar {
                        background: $primary-green;
                        width: vw(92);
                        max-width: 92px;
                        height: vw(92);
                        max-height: 92px;
                        border-radius: 46px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include MQ(XLneg) {
                            display: none;
                        }
                        
                        &.item-big-info-author__avatar--green {
                            background: $primary-green;
                        }
                        &.item-big-info-author__avatar--purple {
                            background: $primary-purple;
                            margin-left: -20px;
                        }

                        img {
                            width: vw(82);
                            max-width: 82px;
                            height: vw(82);
                            max-height: 82px;
                            border-radius: 50px;
                            @include MQ(Lneg) {
                                width: 82px;
                                height: 82px;
                            }
                        }
                    }

                    .item-big-info-author--text {
                        p {
                            padding-left: 2rem;
                            @include font-size(2);
                            @include fluid-type($XLS, $XXL, 18px, 20px);
                            @include MQ(XLneg) {
                                padding-left: 0;
                                font-size: 1.8rem;
                                line-height: 1.4;
                            }
                        }
                    }
                }

                .item-big-info-action {
                    display: flex;
                    align-items: center;
                    position: relative;
                    justify-content: space-between;
                    @include MQ(XLneg) {
                        display: none;
                    }

                    .scroll_down {
                        padding-left: 10px;
                        transform: translateY(50px);
                        display: inline-block;
                        width: 28px;
                        @include MQ(XLneg) {
                            display: none;
                        }
                    }

                    @include MQ(XLSneg) {
                        padding-right: 5vw;
                        padding-bottom: 25px;

                        .button-wide {
                            padding: 15px 35px;
                        }
                    }

                    @include MQ(Lneg) {
                        justify-content: center;
                    }
                }
            }


            .item--prev {
                padding: 0 25px;
                width: 360px;
                .item-big-picture-inner {
                    img {
                        width: 240px;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
            .item--next {
                padding: 0 25px;
                width: 360px;
                .item-big-picture-inner {
                    img {
                        width: 240px;
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .slider-controller {
        width: 320px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 40px;
        max-width: $XLS;
        margin: 0 auto;
        
        @include MQ(XLneg) {
            background: $theme-color-white;
            width: 100%;
            max-width: unset;
            transform: none;
        }
    }
    
    .slider-controller-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 74px;
    
        .icon {
            display: block;
            border-left: 3px solid $theme-slider-navigator;
            border-bottom: 3px solid $theme-slider-navigator;
            width: 16px;
            height: 16px;
            transition: .3s all ease;
            cursor: pointer;
            &:hover {
                border-color: $theme-color-2;
            }
        }
    
        .slider-controller-actions--prev {
            .icon-controller-prev {
                transform: rotate(45deg);
            }
        }
    
        .slider-controller-actions--next {
            .icon-controller-next {
                transform: rotate(-135deg);
            }
        }
    }

}