$button-primary-background: $theme-color-1;
$button-primary-background-hover: $theme-color-2;
$button-primary-color: $theme-color-white;
$button-download-background: $theme-color-3;
$button-download-color: $theme-color-1;
$button-download-icon-color: $theme-color-2;

%radius {
    border-radius: 10px;
}

.button {
    // background: $button-primary-background;
    background: $primary-purple;
    color: $button-primary-color;
    padding: 20px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: $fw-bold;
    position: relative;
    @extend %radius;
    z-index: 0;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: transparent;
        transition: .3s all ease;
        @extend %radius;
        z-index: -1;
    }    
    .icon {
        padding-left: 35px;
        img {
            transition: .3s all ease;
        }
    }
    &:hover {
        // background: $button-primary-background-hover;
        &:before {
            width: 100%;
            background: $button-primary-background-hover;
        }
        .icon {
            img {
                transform: translateX(15px);
            }
        }
    }
}

.button-primary {    
    &--purple {
        &::before {
            border-radius: 0;
        }
    }
}

.button-print {
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    text-transform: none;
    font-family: $Anton;
    font-weight: $fw-regular;
    width: 164px;
    color: $theme-color-white;
    transition: .3s all ease;
    &--green {
        $backgroundColor: $primary-green;
        background: $backgroundColor;
        &:hover {
            background: darken($backgroundColor, 5%);
        }            
    }
    &--purple {
        $backgroundColor: $primary-purple;
        background: $backgroundColor;
        &:hover {
            background: darken($backgroundColor, 5%);
        }
    }
    &::before {
        border-radius: 0;
    }
}

.button-wide {
    padding: 20px 45px;
}

.button-disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .5;
}

.download {
    background: $button-download-background;
    color: $button-download-color;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $Anton;
    width: 100%;
    @include heading-font-size(3.4);
    .button-download {
        color: $button-download-icon-color;
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        padding-left: 10px;
    }
}

.back-to-top {
    display: block;
    position: fixed;
    bottom: 50px;
    right: 35px;
    border-radius: 0;
    @include MQ(Lneg) {
        right: 15px;
        bottom: 25px;
    }
    .back-to-top-box-inner {
        width: 36px;
        height: 36px;
        transform: translateY(36px);
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        background: $primary-green;
        display: flex;
        align-items: center;
        justify-content: center;        
        padding: 25px;
        transition: .3s all ease;
        cursor: pointer;
        &.scroll-up-visible {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }
    }
}
