.block-container-categories {
    margin: 25px 0;    
    padding: 25px 0;
    @include MQ(XLSneg) {
        padding: 0 25px;
    }
    @include MQ(XLneg) {
        padding: 0;
    }
    .category-tags {        
        .category-tags-group {
            display: flex;
            align-items: center;
            @include MQ(XLneg) {
                padding-left: 25px;
            }
            @include MQ(Lneg) {
                width: 100%;
                overflow: auto;
                flex-flow: row nowrap;
                white-space: nowrap;
                -ms-overflow-style: none;
                scrollbar-width: none;
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            li {
                margin-right: 10px;
                @include MQ(Lneg) {
                    float: left;
                    text-align: center;
                }
                &:last-of-type {
                    margin-right: 0;
                    @include MQ(Lneg) {
                        margin-right: 25px;
                    }
                }
                &.category-tag-btn {
                    @include font-size(2); 
                    font-weight: $fw-bold;
                    text-transform: uppercase;                   
                    display: block;
                    padding: 15px 25px;
                    border-radius: 10px;
                    cursor: pointer;
                    transition: .3s all ease;                    
                }
                &.category-btn-plain {
                    background: $theme-color-3;
                }
                &.category-btn-active {
                    background: $theme-color-1;
                    color: $theme-color-white;
                }
                &:hover {
                    background: $theme-color-1;
                    color: $theme-color-white;
                }
            }
        }
    }

    .edu-category-container-wrapper {
        max-width: $max-width;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px 35px 25px;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        @include MQ(Lneg) {
            padding: 10px 0 25px;
            flex-direction: column;
        }
        .edu-category-align-left {
            width: 50%;
            padding-right: 25px;
            @include MQ(XLneg) {
                padding: 0 10px 0 0;
            }
            @include MQ(Lneg) {
                padding: 0;
                width: 100%;
            }            
            svg.link-icon {
                g {
                    .line, .chevron {
                        stroke: $primary-purple;
                    }
                }
            }
        }
        .edu-category-align-right {
            width: 50%;
            padding-right: 25px;
            @include MQ(XLneg) {
                padding: 0 0 0 10px;
            }
            @include MQ(Lneg) {
                padding: 0;
                width: 100%;
            }            
            svg.link-icon {
                g {
                    .line, .chevron {
                        stroke: $primary-green;
                    }
                }
            }
        }
        .edu-category-align-only {                
            padding-right: 0;
            @include MQ(XLneg) {
                padding: 0;
                width: 100%;
            }             
            .edu-category-list {
                @include MQ(XLneg) {
                    max-width: 100%;
                }
            }
        }
        .edu-category-title {
            padding-bottom: 15px;
            padding-left: 25px;
        }
        .edu-category-list {
            max-width: $SM;
            @include MQ(Lneg) {
                max-width: 100%;
            }
            .edu-category-item {
                width: 100%;
                background: $theme-color-3;
                padding: 25px;
                margin-bottom: 25px;
                @include MQ(Lneg) {
                    margin-bottom: 15px;
                }
                &:last-of-type {
                    margin-bottom: 0;
                    @include MQ(Lneg) {
                        margin-bottom: 15px;
                    }
                }
            }
            .edu-category-item-title {
                p {
                    font-family: $Anton;
                    @include font-size(3.4);
                    @include fluid-type($XL, $XLS, 28px, 34px);
                    line-height: 1.2;
                    small {
                        @include font-size(2.4);
                        @include fluid-type($XL, $XLS, 20px, 24px);
                    }
                }
            }
            .edu-category-item-points {
                padding: 15px 0 10px;
                p {
                    font-family: $Anton;
                    @include font-size(2.4);
                    @include fluid-type($XL, $XLS, 21px, 24px);
                    &.edu-purple {
                        color: $primary-purple;
                    }
                    &.edu-green {
                        color: $primary-green;
                    }
                }
            }
            .edu-category-item-link {
                a {
                    display: flex;
                    align-items: center;
                    max-width: 320px;
                    @include MQ(Lneg) {
                        max-width: 100%;
                    }
                }
            }
            .link-text {
                text-decoration: underline;
                text-transform: uppercase;
                color: $theme-color-1;
                font-weight: $fw-bold;
                padding-right: 10px;
                font-size: 16px;
            }
            svg.link-icon {
                g {
                    .line {
                        fill: none;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-miterlimit: 10;
                    }
                    .chevron {
                        fill: none;
                        stroke-width: 2;
                        stroke-linecap: round;
                    }
                }
            }
        }
    }
}
.edu-category-container-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 480px;
    @include MQ(XLneg) {
        position: relative;
        top: 0;
        margin-bottom: 35px;
        width: 100%;
    }
    .edu-category-image-block {
        position: absolute;
        top: 150px;
        right: 0;
        width: 480px;
        @include MQ(XLneg) {
            width: 100%;
            position: relative;
            top: 0;
        }
        img {
            width: 100%;
        }
    }
}