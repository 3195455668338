%push-badge {
    fill: transparent;
    stroke: $theme-color-1;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    transition: .3s all ease;
    opacity: .5;
}

%push-badge-default {
    opacity: 1;
    stroke: $theme-color-1;
}

%push-badge-active {
    opacity: 1;
    fill: $theme-color-2;
    stroke: $theme-color-2;
}
%push-badge-warning {
    opacity: 1;
    fill: $theme-color-warning;
    stroke: $theme-color-warning;
}