.p-layout-container {
    @include MQ(MAXneg) {
        padding: 0 25px;
    }
    @include MQ(XLneg) {
        padding: 0;
    }
    .p-layout-narrow {
        max-width: $XL;
        margin: 0 auto;
        @include MQ(XLneg) {
            padding: 0 25px;
        }
    }
    .p-layout-full {
        max-width: $max-width;
        margin: 0 auto;
    }

    .h-layout {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        padding: 50px 0 25px;
        width: col(12);
        @include MQ(Lneg) {
            flex-direction: column;
            padding: 25px 0;
        }
        .layout-title {
            width: col(6);
            padding-right: 25px;
            @include MQ(XLneg) {
                width: col(12);
                padding: 25px;
            }
            @include MQ(Lneg) {
                padding: 25px 0;
            }
            h1 {
                font-family: $Anton;
                font-weight: $fw-regular;
                @include font-size(5.4);
                @include fluid-type($XL, $XLS, 40px, 54px);
                color: $primary-green;
                @include MQ(XLneg) {
                    line-height: 1.4;
                }
                @include MQ(Lneg) {
                    @include font-size(4);
                }
                @include MQ(Mneg) {
                    @include font-size(3.4);
                }
            }
        }
        .layout-media {
            width: col(6);   
            padding-left: 25px;  
            @include MQ(Lneg) {
                width: col(12);
            }       
            img {
                width: 100%;
                @include MQ(Lneg) {
                    width: 100vw;
                    margin-left: -50px;
                }
            }
        }
    }

    .b-layout {
        width: col(12);        
        .b-layout-section {
            display: block;
        }        

        .layout-header {
            margin-bottom: 25px;
            h2 {
                &.layout-header {
                    font-family: $Anton;
                    font-weight: $fw-regular;
                    @include font-size(4);
                    @include fluid-type($XL, $XLS, 30px, 40px);
                    @include MQ(Lneg) {
                        @include font-size(3);
                    }
                }
                &.layout-header--color {
                    color: $primary-purple;
                }
            }
        }

        
        .layout-content-wrapper {
            display: flex;   
            flex-flow: row wrap;
            padding-bottom: 75px;
            @include MQ(XLneg) {
                padding-bottom: 0;
            }

            &.mb-pb-none {
                padding-bottom: 0;
            }

            .c-heading {
                width: col(6);
                @include MQ(Lneg) {
                    width: col(12);
                    padding: 25px 0;
                }
                &.c-heading-wide {
                    width: col(12);
                    min-height: 80px;
                    @include MQ(XLneg) {
                        min-height: 55px;
                    }
                    @include MQ(Lneg) {
                        min-height: auto;
                    }
                }
            }

            .content-header {
                font-family: $Anton;
                font-weight: $fw-regular;
                @include font-size(4);
                @include fluid-type($XL, $XLS, 30px, 40px);
                line-height: 1.4;
                @include MQ(Lneg) {
                    @include font-size(3);
                }
                &.content-header--primary {
                    color: $theme-color-1;
                }
                &.content-header--color {
                    color: $primary-purple;
                }
            }
    
            .c-column {
                width: col(6);
                @include MQ(Lneg) {
                    width: col(12);
                }
                &--left {
                    padding-right: 25px;

                    @include MQ(Lneg) {
                        padding: 0;
                    }
                }
                &--right {
                    padding-left: 25px;
                    z-index: 1;
                    @include MQ(Lneg) {
                        padding: 0;
                    }
                }
                &--align-end {
                    align-self: flex-end;
                }
                &--align-center {
                    align-self: center;
                }
                .c-column-heading {    
                    margin-bottom: 35px;            
                    @include MQ(Lneg) {
                        padding: 25px 0;
                    }
                }
                .text-long {
                    min-height: 150px;
                }
                .layout-column-text {
                    @include font-size(2.4);
                    @include fluid-type($XL, $XLS, 20px, 24px);
                    color: $theme-color-1;
                    padding-bottom: 20px;
                    padding-top: 20px;
                    @include MQ(XLneg) {
                        padding: 10px 0;
                    }
                    @include MQ(Lneg) {
                        padding: 15px 0;
                        @include font-size(2.4);
                    }
                    &--bold {
                        font-weight: $fw-bold;
                    }
                    .text-italic {
                        font-style: italic;
                    }
                    .text-color {
                        color: $primary-purple;
                    }
                }  
                .c-column-layout-list,
                .s-column-layout-list {
                    .layout-spacer {
                        padding-bottom: 25px;
                    }
                    .layout-list {
                        list-style: disc outside; 
                        padding-left: 20px;
                        .layout-list-item {
                            @include font-size(2.4);
                            @include fluid-type($XL, $XLS, 20px, 24px);
                            color: $theme-color-1;
                            @include MQ(Lneg) {
                                @include font-size(2.4);
                            }
                        }                     
                    }
                }  
                
                .c-column-media-tall {
                    width: col(12);
                    font-size: 0;
                    margin-bottom: -125px;
                    @include MQ(Lneg) {
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                    img {
                        width: 100%;
                        @include MQ(Lneg) {
                            width: 100vw;
                            margin-left: -25px;
                        }
                    }
                }
                &.c-layout-full {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    position: relative;
                    padding: 75px 0 25px;
                    @include MQ(Lneg) {
                        margin-top: 0;
                    }
                    &.c-layout-background {
                        $backgroundColor: $theme-color-3;
                        background: $backgroundColor;
                        width: 100vw;
                        &:before {
                            content: '';
                            background: $backgroundColor;
                            position: absolute;
                            top: 0;
                            left: 50%;
                            right: 50%;
                            margin-left: -50vw;
                            margin-right: -50vw;
                            width: 100vw;
                            height: 100%;
                            z-index: -1;
                        }
                    }
                    .s-column-heading {
                        width: 100%;
                        padding-bottom: 50px;
                    }
                    .s-column-wide {
                        width: 100%;
                    }
                    .s-column {
                        width: col(6);
                        z-index: 1;
                        @include MQ(Lneg) {
                            width: col(12);
                        }
                        &--right {
                            padding-left: 25px;
                            @include MQ(Lneg) { 
                                padding-left: 0;
                            }
                            &.s-row-start {
                                align-self: flex-start;
                            }
                            &.s-row-end {
                                align-self: flex-end;
                                @include MQ(Lneg) {
                                    align-self: flex-start;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }

    .f-layout {
        padding: 35px 0;      
        @include MQ(Lneg) {
            display: block;
            margin: 0 auto;
        }          
        .layout-author-name {
            @include font-size(2.2);
            @include fluid-type($XL, $XLS, 18px, 22px);
            font-weight: $fw-semibold;
            font-style: italic;
            position: relative;
            padding-top: 15px;
            @include MQ(Lneg) {
                text-align: center;
                @include font-size(2.2);
            }          
            &:before {
                content: '';
                position: absolute;
                display: block;
                bottom: 100%;
                top: 0;
                width: 80px;
                height: 1px;
                background: $theme-color-1;
                @include MQ(Lneg) {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}