/* FUNCTIONS */

// Fluid Text Size
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

// Responsive Width/Height
@function vw($value){
    @return ($value / 1680) * 100 * 1vw;
}

// Columns
@function col($num) {
    @return 100% / $grid-columns * $num;
    // Usage e.g width: col(4) = (100% / 12 * 4)
}

@for $i from 1 through 6 {
    h#{$i} {
        margin: 0;
        padding: 0;
        font-family: $Anton;
        box-shadow: 9px 0 0 7px transparent, -9px 0 0 7px transparent;
    }
}

p {
    box-shadow: 9px 0 0 7px transparent, -9px 0 0 7px transparent;
}
