.page-content-cards--clinical {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
    padding: 50px 0;
    @include MQ(XLSneg) {
        padding: 25px;
    }
    
    .page-content-card-item {
        width: col(6);
        @include MQ(XLneg) {
            width: col(12);
        }
        &:nth-of-type(odd) {
            padding-right: 10px;
            padding-bottom: 20px;
            @include MQ(XLneg) {
                padding: 0 0 20px 0;
            }
        }
        &:nth-of-type(even) {
            padding-left: 10px;
            padding-bottom: 20px;
            @include MQ(XLneg) {
                padding: 0 0 20px 0;
            }
        }
    }
    .page-content-card-item a {
        width: col(12);
        display: flex;
        align-items: stretch;   
        flex-flow: row nowrap;    
        @include MQ(Mneg) {
            flex-direction: column;
        } 
        &:hover {
            .page-content-card-item-banner {
                img {
                    transform: scale(1.2);
                }
            }
            .page-content-card-item-info {
                .item-info-title {
                    p {
                        color: $primary-green;
                    }
                }
                .item-info-button {
                    .button-text {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    .page-content-card-item-banner {
        width: col(6);
        font-size: 0;
        overflow: hidden;
        width: 385px;
        height: 240px;        
        @include MQ(Mneg) {
            width: col(12);
            height: 244px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: .3s all ease;
        }
    }

    .page-content-card-item-info {
        width: col(6);
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @include MQ(Mneg) {
            width: col(12);
            justify-content: flex-start;
        }
        .item-info-wrapper {
            padding: 10px 0 10px 25px;
            @include MQ(Mneg) {
                padding: 25px 0;
            }
        }
        .item-info-title {
            padding-bottom: 25px;
            p {
                font-family: $Anton;
                font-weight: $fw-regular;
                @include font-size(2.8);
                @include fluid-type($XL, $XLS, 24px, 28px);
                transition: .3s all ease;
            }
        }
        .item-info-button {
            display: flex;
            align-items: center;
            .button-text {
                @include font-size(1.6);
                color: $dark-navy;
                font-weight: $fw-semibold;
                font-family: $Barlow;
                transition: .3s all ease;
            }
            svg {
                margin-left: 10px;
                g {
                    .line, .path {
                        fill: none;
                        stroke: $dark-navy;
                        stroke-linecap: round;
                        stroke-width: 1;
                    }
                    .line {
                        stroke-miterlimit: 10;
                    }
                }
            }
        }
    }
}