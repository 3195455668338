$SpinnerIconSize: 21px;
$SpinnerBorderSize: 3px;
$SpinnerBorderRadius: ($SpinnerIconSize / 2);

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(25px)
    }

    25% {
        opacity: 0;
        transform: translateY(25px)
    }

    50% {
        opacity: .5;
        transform: translateY(0)
    }

    75%,
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

.form-container-action {    
    .form-container-sending-message {
        display: flex;
        align-items: center;
        background: transparent;
        padding-left: 0;
        opacity: 0;
        animation: fadeUp 0s forwards ease-in-out;
        font-size: 2rem;
        font-weight: $fw-bold;
        padding: 18px 45px 18px 0;
        cursor: default;
        pointer-events: none;

        .spinner {
            width: $SpinnerIconSize;
            height: $SpinnerIconSize;
            display: block;
            border-radius: $SpinnerBorderRadius;
            border-top: $SpinnerBorderSize solid $primary-purple;
            border-right: $SpinnerBorderSize solid transparent;
            border-left: $SpinnerBorderSize solid $primary-purple;
            animation: spinner .5s linear infinite;
            margin-right: 15px;
        }

        .spinner-text {
            @include font-size(1.8);
            font-weight: $fw-semibold;
            color: $primary-purple;
            display: block;
            padding: 15px 25px 15px 0;  
        }
    }    
}