.content-block--split-page {
    padding: 135px 25px 100px;
    display: flex;
    align-items: flex-start;
    margin: 0 auto;
    max-width: $max-width;

    @include MQ(Lneg) {
        flex-direction: column;
        padding: 25px;
    }

    .split-page-info {
        width: 40%;
        flex-basis: 40%;
        padding-right: 75px;

        @include MQ(Lneg) {
            width: 100%;
            flex-basis: 100%;
            padding-right: 0;
        }
    }

    .split-page-categories {
        width: 60%;
        flex-basis: 60%;

        display: flex;
        align-items: center;

        @include MQ(Lneg) {
            width: 100%;
            flex-basis: 100%;
        }

        @include MQ(Mneg) {
            flex-direction: column;
        }

        .split-page-category {
            margin-right: 25px;
            width: 100%;
            max-width: 389px;
            height: 100%;
            max-height: 250px;

            &:nth-last-child() {
                margin-right: 0;
            }

            @include MQ(XLneg) {
                max-width: 49%;
                max-height: 100%;
                margin-bottom: 20px;
            }

            @include MQ(Lneg) {
                max-width: 100%;

                &:last-of-type() {
                    margin-right: 0;
                }
            }

            @include MQ(Mneg) {
                margin-right: 0;
            }

            figure {
                position: relative;
                width: 100%;
                height: 100%;

                @include MQ(XLneg) {
                    font-size: 0;
                }

                a.item-link-card-anchor {
                    display: inline-block;

                    @include MQ(XLneg) {
                        width: 100%;
                    }

                    &:hover {
                        .item-link-card-banner .item-link-card-banner-inner img {
                            transform: scale(1.25);
                        }

                        .item-link-card-info .item-link-card-info-action .action-link {
                            flex-grow: 1;
                        }
                    }
                }

                .item-link-card-banner {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    @include MQ(XLSneg) {
                        max-width: 100%;
                        max-height: 100%;
                    }

                    .item-link-card-banner-inner {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                        z-index: 0;

                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            opacity: .4;
                            background: $theme-color-1;
                        }

                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: relative;

                            @include MQ(XLSneg) {
                                max-width: 100%;
                                max-height: 100%;
                            }

                            transition: .3s all ease;
                        }
                    }

                }

                .item-link-card-info {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 0 25px 25px 25px;

                    @include MQ(XLSneg) {
                        padding: 0 25px 0;
                    }

                    @include MQ(XLSneg) {
                        padding: 0 25px 25px 25px;
                    }

                    .item-link-card-info-title {
                        padding-bottom: 10px;

                        h3 {
                            @include MQ(Lneg) {
                                @include font-size(4);
                            }

                            @include MQ(Mneg) {
                                @include font-size(2.8);
                            }
                        }
                    }

                    .item-link-card-info-action {
                        display: flex;
                        align-items: center;

                        .action-link {
                            padding-right: 10px;
                            transition: .3s all ease;
                        }
                    }
                }
            }
        }
    }
}