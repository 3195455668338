.push-notification-block {
    position: fixed;
    top: 25px;
    right: 25px;
    background: $theme-color-white;
    width: 100%;
    max-width: 420px;
    z-index: 100;
    transition: .3s all ease;
    box-shadow: 0 0 5px 0px transparentize($theme-color-1, .5);
    padding: 10px;
    opacity: 0;
    transform: translateX(100%);
    pointer-events: none;    
    @include MQ(Lneg) {
        top: 0;
        right: 0;
        max-width: 100%;
        padding: 15px;
    }

    .push-block-wrapper {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        position: relative;
    }

    .push-block-close {
        position: absolute;
        top: 8px;
        right: 4px;
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 8px;
        @include MQ(Lneg) {
            padding-right: 0;
        }
        .btn-close {            
            border: 2px solid $theme-color-1;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 18px;
            span {
                display: block;
                width: 14px;
                height: 2px;
                background: $theme-color-1;
                position: relative;
                transform: rotate(45deg);
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 14px;
                    height: 2px;
                    background: $theme-color-1;
                    transform: rotate(90deg);
                }
            }
        }
    }

    .push-block-icon {
        width: 84px;
        padding-top: 15px;
        img {
            width: 100%;
            max-width: 60px;
            display: block;
            margin: 0 auto;
            @include MQ(Lneg) {
                margin-left: 0;
            }
        }
    }
    .push-block-content {
        width: calc(100% - 100px);
        margin-left: auto;
        color: $theme-color-1;
        padding-bottom: 35px;
        padding-top: 10px;
        padding-right: 35px;
        @include MQ(Lneg) {
            padding-bottom: 15px;
            margin-left: 0;
            width: calc(100% - 125px);
        }
        .push-block-title {
            font-size: 1.8rem;
            padding-bottom: 10px;
            line-height: 1.2;
        }
        .push-block-text {
            font-size: 1.6rem;
            line-height: 1.2;
            font-weight: $fw-semibold;
        }
    }

    .push-block-action {
        display: block;
        width: 100%;
        @include MQ(Lneg) {
            padding-top: 10px;
        }
        a {
            margin-left: auto;
            width: 184px;
            font-weight: $fw-semibold;
            color: $theme-color-white;
            font-size: 1.6rem;
            background: $theme-color-1;
            display: block;
            text-align: center;
            padding: 8px 25px;            
            transition: .3s all ease;
            &:hover {
                background: $theme-color-2;
            }
            @include MQ(Lneg) {
                width: 100%;
                padding: 12px 25px;
            }
        }
    }
}