//User Role Modal
.modal-user-role {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: transparentize($dark-navy, .1);
    z-index: 99999;
    display: none;
    &.modal-user-role-open {
        display: block;
    }

    .user-role-container {
        width: 800px;
        height: 420px;
        background: $theme-color-white;
        padding: 50px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include center();
        @include MQ(Mneg) {
            width: 90vw;
            height: 75vh;
        }
    }

    .user-role-container-title {
        flex: 1 0 auto;
        img {
            width: 100%;
            max-width: 190px;
        }
    }

    .user-role-container-body {
        flex: 1 0 auto;
        p {
            font-family: $Anton;
            font-weight: $fw-regular;
            text-align: center;
        }
        .user-role-body-primary-text {
            color: $primary-green;
            @include font-size(2.6);
        }
        .user-role-body-secondary-text {
            @include font-size(3.4);
        }
    }

    .user-role-container-action {
        width: 100%;
        margin: 0 auto;
        .user-action-choice-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            @include MQ(Mneg) {
                flex-direction: column;
            }
            a.user-action-choice {
                position: relative;
                display: flex;
                align-items: center;       
                margin: 0 10px;
                @include MQ(Mneg) {
                    margin: 10px 0;
                    align-items: flex-start;
                    justify-content: center;
                    width: 100%;
                }     
                &:hover {
                    .user-action-box {
                        border-color: $primary-purple;
                        &::before {
                            width: 36px;
                            height: 36px;
                        }
                    }
                    .user-action-text {
                        color: $primary-purple;
                    }
                }                         
                .user-action-box {
                    display: block;
                    width: 48px;
                    height: 48px;
                    border: 2px solid $primary-green;
                    position: relative;
                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 0;
                        height: 0;
                        background: $primary-purple;
                    }
                }
                .user-action-text {
                    @include font-size(2.4);
                    font-family: $Anton;
                    font-weight: $fw-regular;
                    color: $primary-green;
                    padding: 8px 8px 8px 15px;
                }
            }
        }
    }
}