@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
      visibility: visible;
  }
}

@keyframes slideUp {
  from {
      transform: translateY(50px);
  }
  to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
  }
}

@keyframes slideInRight {
  from {
      transform: translateX(-325px) scale(-1, 1);
  }
  to {
      transform: translateX(0) scale(-1, 1);
      opacity: 1;
      visibility: visible;
  }
}

@keyframes slideInLeft {
  from {
      transform: translateX(325px);
  }
  to {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
  }
}
@keyframes slideInTop {
  from {
      transform: translateY(50px);
  }
  to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
  }
}
@keyframes slideInBottom {
  from {
      transform: translateY(-50px);
  }
  to {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
  }
}

@keyframes loading {
	0%, 60%, 100% {
		transform: initial;
    background-color: $theme-color-1;
	}

	30% {
		transform: translateY(-7px);
    background-color: lighten($theme-color-1, 15%);
	}
}

@keyframes wobble {
  0% {
    transform: none;
  }

  15% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(5%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(0%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  100% {
    transform: none;
  }
}


@keyframes placeholder-blink {
  0%, 60%, 100% {
    opacity: 0.5;
	}

	30% {
    opacity: 1;
	}
}

.webinar-fade-enter-active, .webinar-fade-leave-active {
  transition: opacity .5s;
}
.webinar-fade-enter, .webinar-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}