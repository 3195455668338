body>header>.container,
body>section>.container,
body>footer>.container {
    max-width: 1460px;
    padding: 0 20px;
    margin: 0 auto;
}

body>section {
    max-width: $XXL;
    margin: 0 auto;
}

.page-return {
    max-width: $XLS;
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 0 25px;
    @include MQ(Lneg) {
        padding: 0 15px;
    }
    .heading-block-return {
        margin-top: -24px;       

        a {
            display: block;
            max-width: 320px;
            padding: 10px 25px 10px 0;

            @include MQ(XLSneg) {
                padding: 10px 25px;
            }
            @include MQ(Lneg) {
                padding: 10px 0;
            }

            &:hover {
                p {
                    color: $theme-navbar-link-hover;
                }
            }
            p {
                display: flex;
                align-items: center;
                transition: .3s all ease;
                font-weight: $fw-semibold;
                @include font-size(1.8);
                span {
                    padding-right: 15px;
                    padding-bottom: 3px;
                }
            }
        }
    }
    .heading-block-return-wide {
        a {
            max-width: 420px;
        }
    }
}

.page-container {
    max-width: $XLS;
    margin: 0 auto;
    flex: 1 0 auto;
    width: 100%;
    position: relative;

    &.page-container-webinar-item {
        display: flex;
        flex-flow: row wrap;
        .heading-block-return {
            width: 100%;
        }
    }

    &--responsive {
        @include MQ(MAXneg) {
            padding: 0 25px;
        }
        @include MQ(Lneg) {
            padding: 0;
        }
    }

    .heading-block {
        margin-top: -45px;
        @include MQ(XLSneg) {
            padding: 0 25px;
        }
        @include MQ(Lneg) {
            margin-top: -35px;
        }
    }
    
    .heading-block-group-return {
        margin-top: -40px;
        display: flex;    
        align-items: center;
                
        @include MQ(Lneg) {
            padding: 0 25px;
        }
        .return-link {
            padding-right: 25px;
            a {
                display: block;
                margin-top: 15px;
                padding: 10px 25px 10px 0;
    
                @include MQ(XLSneg) {
                    padding: 10px 25px;
                }
                @include MQ(Lneg) {
                    padding: 10px 0;
                }
    
                &:hover {
                    p {
                        color: $theme-navbar-link-hover;
                    }
                }
                p {
                    display: flex;
                    align-items: center;
                    transition: .3s all ease;
                    font-weight: $fw-semibold;
                    @include font-size(1.8);
                    span {
                        padding-right: 15px;
                        padding-bottom: 3px;
                    }
                }
            }
        }
    }
}