.text-right-lg {
    text-align: right;
    @include MQ(Lneg) {
        text-align: left;
    }
}

.main-header-banner-mobile {
    padding: 35px 0;
    .main-header-banner-mobile-image {
        width: 100%;
        img {
            width: 100%;
        }
    }
}

.landing-article {
    padding: 0 25px;

    .block-body-section-split {
        .block-body-section-text {
            @include MQ(XLneg) {
                width: 100%;
                flex-basis: 100%;
            }
        }
    }
    .block-body-section-image {
        width: 540px;
        img {
            width: 100%;
        }

        .block-body-section-text {
            width: 100%;
            padding-top: 45px;
        }
    }
    .block-body-section-text {
        padding-top: 150px;
        @include MQ(XLneg) {
            padding-top: 25px;
            width: 100%;
        }
        h2 {
            padding-bottom: 25px;
        }
        p {
            margin-bottom: 25px;
            width: 95%;
            @include MQ(XLneg) {
                width: 100%;
            }
        }
    }
}

.block-body-columns {
    max-width: $max-width;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    margin-bottom: 100px;
    @include MQ(XLneg) {
        padding: 25px;
    }
    @include MQ(Lneg) {
        margin-bottom: 25px;
        padding: 35px 25px;
    }
    &--multiple {
        padding: 35px 25px;
        &:last-of-type {
            padding-bottom: 25px;
        }
        & > div {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-flow: row wrap;
            @include MQ(Lneg) {
                flex-direction: column;
                justify-content: flex-start;
            }
        }
    }
    &--box-color {
        background: $theme-color-3;
    }
    &--box-color-mobile {
        padding-bottom: 0;
        margin-bottom: 0;
        &:last-of-type {
            padding-bottom: 0;
        }
        @include MQ(Lneg) {
            background: $theme-color-3;
            &:last-of-type {
                padding: 0 25px 25px;
            }
            .block-body-section-box-container {
                flex-direction: column-reverse;
            }
        }
    }
    &--no-margin-bottom {
        margin-bottom: 0;
        @include MQ(Lneg) {
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }

    .block-body-section-title {
        width: 100%;
        padding: 45px 0;
        &.block-padding-bottom {
            @include MQ(Lneg) {
                padding-bottom: 25px;
            }
        }
        @include MQ(Lneg) {
            padding: 0;
        }
    }

    .block-body-section-box-container {
        max-width: $XL;
        margin: 0 auto;
        .block-body-section-half {
            width: 50%;
            position: relative;
            @include MQ(Lneg) {
                width: 100%;
            }
            &:first-of-type {
                padding: 0 25px 0 0;
                @include MQ(Lneg) {
                    padding: 0;
                }
            }
            &:last-of-type {
                padding: 0 0 0 25px;
                @include MQ(Lneg) {
                    padding: 0;
                }
            }
        }
        .block-body-section-image {
            width: 690px;
            transform: translate(-200px, 100px);
            @include MQ(XLSneg) {
                width: vw(1000);
                transform: translate(-20vw, 10vw);
            }
            @include MQ(Lneg) {
                width: 100vw;
                transform: none;
                padding: 25px 0;
            }
            .block-image-wrapper {
                width: 100%;
                font-size: 0;
                img {
                    width: 100%;
                    @include MQ(XLSneg) {
                        padding-right: 35px;
                    }
                    @include MQ(Lneg) {
                        margin-left: -25px;
                    }
                }
            }
        }
        .block-body-section-image-static {
            width: 768px;
            margin-top: -5vw;
            @include MQ(XLSneg) {
                width: vw(768);
            }
            @include MQ(Lneg) {
                margin-top: 0;
                width: 100vw;
                transform: none;
                padding: 25px 0;
                margin-left: -25px;
            }
            .block-image-wrapper {
                width: 100%;
                font-size: 0;
                img {
                    width: 100%;
                }
            }
        }
    }

    .block-body-section-box-container-max {
        width: 100%;
        max-width: $max-width;        

        .block-body-section-graph {
            width: 100%;
            margin: 0 auto;
            padding: 25px;
            .block-body-section-caption {
                max-width: $XL;
                margin: 0 auto;
                padding-bottom: 50px;
                .block-heading--diagram-title {
                    @include font-size(2.4);
                    @include fluid-type($XL, $XLS, 21px, 24px);
                    font-family: $Anton;
                    font-weight: $fw-regular;
                }
                @include MQ(Lneg) {
                    margin-left: -25px;
                }
            }
            .graph-wrapper {
                @include MQ(Lneg) {
                    overflow-x: auto;
                    width: 100vw;
                    margin-left: -50px;
                    padding-right: 25px;
                    padding-left: 25px;
                    white-space: nowrap;
                }
                img {
                    width: 100%;
                    @include MQ(Lneg) {
                        width: auto;
                        min-width: 100vw;
                    }
                }
            }
            .graph-download {
                max-width: $XL;
                .block-text__caption {
                    font-style: italic;
                    @include font-size(2);
                }
                @include MQ(Lneg) {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}