.cookies-alert {
    background-color: $theme-color-white;
    box-shadow: 0 0 5px 2px rgba(225, 225, 225, .3);
    color: $theme-color-1;
    font-weight: $fw-semibold;
    text-align: center;
    padding: 25px 0;
    display: none;
    position: fixed;
    bottom: 35px;
    right: 35px;
    width: 100%;
    max-width: 540px;
    z-index: 100;

    .cookies-button {
        display: inline-block;
        width: 160px;
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.8;
        color: $theme-color-white;
        background-color: $primary-green;
        cursor: pointer;
        margin: 5px 20px 5px 20px;
        transition: all .2s ease-in-out;
        outline: none;
        border-radius: 0;
        letter-spacing: 0.05rem;
        font-weight: $fw-bold;

        &:hover {
            background-color: darken($theme-color-1, 10%);
            color: $theme-color-white;
        }
    }

    p {
        display: inline-block;
        font-size: 1.6rem;
        line-height: 1.4;
        text-align: center;
        margin: 5px;
        letter-spacing: 0.05rem;

        a {
            color: $theme-color-white;
            text-decoration: underline;

            &:hover {
                color: $theme-color-2;
            }
        }
    }
}