.section-container-form {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $XLS;
    margin: 0 auto;
    padding: 0 0 75px 75px;
    @include MQ(XLSneg) {
        padding: 0 25px 45px;
    }

    @include MQ(Lneg) {
        flex-direction: column;
        padding: 0 0 50px;
    }
    .form-block-column {
        width: col(6);
        display: flex;
        flex-direction: column;
        padding: 35px 0;
        &.form-block-column-wide {
            width: col(7);
            @include MQ(Lneg) {
                width: col(12);
            }
        }
        @include MQ(Lneg) {
            width: col(12);
        }
        .form-container {
            padding: 35px 125px 35px 25px;
            margin: 0 auto;
            width: 100%;
            @include MQ(XLSneg) {
                padding: 35px 75px;
            }
            @include MQ(XLneg) {
                padding: 25px 35px;
            }
            .form-container-title {
                padding-bottom: 25px;
            }

            .form-container-input {
                padding-bottom: 15px;
                &.mb5 {
                    padding-bottom: 5px;
                }
                input::placeholder, textarea::placeholder {
                    color: $theme-color-1;
                    font-weight: $fw-semibold;
                }
                label {
                    textarea {
                        @include font-size(1.8);
                        border-color: transparent;
                        outline: none;
                        box-shadow: none;
                        padding: 20px;
                        width: 100%;
                        color: $theme-form-input-text;
                        background: $theme-form-background;
                        text-transform: uppercase;
                        font-weight: $fw-semibold;
                        resize: none;
                        height: 150px;
                        
                        @include MQ(XLneg) {
                            padding: 10px;
                            @include font-size(1.8);
                        }
                        @include MQ(Lneg) {
                            max-width: unset;
                        }
                        &:focus,
                        &:active {
                            box-shadow: 0 0 0 2px $theme-form-input-background-focus;
                        }
                        &:disabled {
                            pointer-events: none;
                            opacity: 0.5;
                            cursor: default;
                        }
                    }
                    input {
                        @include font-size(1.8);
                        border-color: transparent;
                        outline: none;
                        box-shadow: none;
                        padding: 20px; 
                        width: 100%;
                        color: $theme-form-input-text;
                        background: $theme-form-background;
                        text-transform: uppercase;
                        font-weight: $fw-semibold;
                        @include MQ(XLneg) {
                            padding: 10px;
                            @include font-size(1.8);
                        }
                        @include MQ(Lneg) {
                            max-width: unset;
                        }
                        &:focus,
                        &:active {
                            border-color: $theme-form-input-background-focus;
                        }
                        &:disabled {
                            pointer-events: none;
                            opacity: 0.5;
                            cursor: default;
                        }
                    }
                }
            }

            .form-container-action {
                .button {
                    padding: 25px 35px;
                    font-size: 2rem;
                    width: 360px;
                    @include MQ(XLneg) {
                        width: 100%;
                        padding: 20px 25px;
                    }
                    @include MQ(Mneg) {
                        padding: 15px 20px;
                        @include font-size(1.8);
                    }
                }
                .form-container-success-message {
                    @include font-size(1.8);
                    font-weight: $fw-semibold;
                    color: $primary-purple;
                    display: block;
                    padding: 15px 25px 15px 0;                    
                }
                .form-button-new-message {
                    @include font-size(1.8);
                    background: $primary-purple;
                    color: $theme-color-white;
                    display: block;
                    padding: 15px 25px;
                    font-weight: $fw-semibold;
                    text-align: center;
                    border-radius: 10px;
                }
                .form-button-plain {
                    @include font-size(1.8);
                    display: inline-block;
                    padding: 15px 25px;
                    color: $primary-purple;
                    font-weight: $fw-semibold;
                    text-decoration: underline;
                }
                .form-buttons-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: $primary-purple;
                    padding-top: 35px;
                }
            }            
        }
    }

    .image-block-column {
        width: col(5);
        &.image-block-column-medium {
            width: col(5);
            @include MQ(Lneg) {
                width: col(12);
            }
        }
        @include MQ(Lneg) {
            width: col(12);
        }
        .image-block {
            width: 100%;
            img {
                width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
    }    
}