.media-details-video {
    width: 100%;
}

.content-block-media-template {
    padding: 35px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;

    @include MQ(XLneg) {
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 35px 0;
    }

    .media-details-title {
        width: 42%;
        flex-basis: 42%;
        @include MQ(XLneg) {
            width: 100%;
            flex-basis: 100%;
            padding: 25px;
        }

        h1 {
            @include MQ(Lneg) {
                @include font-size(4.4);
            }
            @include MQ(Mneg) {
                @include font-size(3.6);
            }
            @include MQ(SMneg) {
                @include font-size(3);
            }
            .text-placeholder {
                display: block;
                width: 83%;
                height: 35px;
                background: $theme-color-1;
            }
        }

        p {
            padding: 15px 0 0;
            .text-placeholder {
                display: block;
                width: 46%;
                height: 35px;
                background: $theme-color-1;
            }

            span {
                display: block;
                color: $theme-color-2;
            }
        }
    }

    .media-details-video {
        width: 50%;
        flex-basis: 50%;
        @include MQ(XLneg) {
            width: 100%;
            flex-basis: 100%;
        }
        .media-player {
            .media-placeholder {
                display: block;
                width: 100%;
                height: 360px;
                background: $theme-color-1;
            }
        }
        .media-player-banner {            
            iframe {
                width: 100% !important;
                height: -webkit-fit-available;
            }
        }
    }
}