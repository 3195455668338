%box-fill-default {
    background: $theme-section-colored;
}

%box-fill-green {
    background: $theme-section-green;
    padding: 50px;

    @include MQ(Lneg) {
        padding: 35px 25px;
    }
}
%box-fill-purple {
    background: $primary-purple;
    padding: 50px 85px;

    @include MQ(Lneg) {
        padding: 35px 25px;
    }
}

%box-fill-border {
    border: 3px solid $theme-section-green;
    padding: 50px 85px;

    @include MQ(Lneg) {
        padding: 35px 25px;
    }
}
%box-fill-border-purple {
    border: 3px solid $primary-purple;
    padding: 50px 85px;

    @include MQ(Lneg) {
        padding: 35px 25px;
    }
}

%list-squared {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 0 0 10px;

    &:before {
        content: '';
        display: block;
        padding: 4px;
        background: $theme-section-primary;
        margin-right: 10px;
        margin-top: 12px;
    }
}

%list-rounded {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 0 0 10px;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        padding: 3px;
        border-radius: 5px;
        background: $theme-section-primary;
        margin-right: 10px;
        margin-top: 15px;
    }
}

%print-action {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-family: $Anton;
    font-weight: $fw-semibold;
    letter-spacing: 0.05rem;
    line-height: 1.4;
    font-size: 2.2rem;
    border-radius: 0;
    height: 64px;

    img {
        width: 24px;
        margin-left: 25px;
    }

    @include MQ(Lneg) {
        justify-content: space-between;
    }
}

.block-body-section-text-reference {
    .block-text__default {
        font-size: 2.2rem;
    }
}

.text-center {
    text-align: center;
}

.text-padding {
    padding: 0 65px;
}

.block-body {
    &:last-of-type {
        padding-bottom: 100px;
    }

    .divider {
        width: 100%;
        border: 1px solid $theme-divider-color-secondary;
        margin: 75px auto 0;
        max-width: $XLS;
        padding: 0 25px;
        &.divider-purple {
            border-color: $primary-purple;
        }

        @include MQ(Lneg) {
            width: calc(100% - 50px);
            margin: 50px auto 0;
        }
    }

    .box-info {
        @extend %box-fill-green;
    }
    .box-info-purple {
        @extend %box-fill-purple;
    }

    .box-info-bordered {
        @extend %box-fill-border;
    }
    .box-info-bordered-purple {
        @extend %box-fill-border-purple;
    }

    .box-info-2 {
        @extend %box-fill-default;
    }

    .box-info-list-rounded {
        padding-right: 100px;

        @include MQ(Lneg) {
            padding-right: 0;
        }

        li {
            @extend %list-rounded;
        }
    }

    .block-body-section-list {    
        list-style: outside disc;
        .block-list-item {
            padding: 0;
            margin-left: 1em;
        }
    }

    .block-body-section-title {
        margin: 0 auto;
        max-width: $XL;
        padding-top: 75px;
        padding-bottom: 75px;

        @include MQ(MAXneg) {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @include MQ(XLneg) {
            padding-top: 35px;
            padding-bottom: 35px;
        }

        @include MQ(Lneg) {
            padding: 25px 25px 50px;
        }

        .block-body-section-warning-message {            
            padding-top: 35px;
            p {
                &.warning-text {
                    color: $primary-purple;
                }
            }
        }
    }

    .block-body-section-split {
        display: flex;
        flex-flow: row wrap;
        margin: 0 auto;
        max-width: $XL;

        @include MQ(Lneg) {
            flex-direction: column;
            padding: 0 25px;
        }

        &.split-align-end {
            align-items: flex-end;

            @include MQ(Lneg) {
                align-items: center;
                padding-top: 25px;
                padding: 0;
            }

            .block-body-section-text {
                padding: 0 25px 50px;

                @include MQ(Lneg) {
                    padding-top: 25px;
                }
            }
        }

        .block-body-sub-title {
            width: 100%;
            flex-basis: 100%;

            h3 {
                padding-bottom: 45px;
            }

            .box-info,
            .box-info-purple {
                margin-bottom: 35px;

                h4 {
                    text-align: center;
                }
            }

            .section-title {
                margin-bottom: 35px;
            }
        }

        .block-body-section-text {
            width: 50%;
            flex-basis: 50%;
            padding-right: 25px;
            padding-bottom: 50px;
            padding-left: 0;

            @include MQ(Lneg) {
                width: 100%;
                flex-basis: 100%;
                padding-right: 0;
            }

            p {
                padding: 0 15px 0 0;
            }
        }

        .block-body-section-text--full {
            width: 100%;
            flex-basis: 100%;
            &.bbst-pb-lg {
                padding-bottom: 50px;
            }
        }

        .block-body-section-text-columns {
            column-count: 2;
            column-gap: 60px;
            padding-right: 55px;
            padding-bottom: 50px;
            padding-left: 0;
            
            &.bbst-pb0 {
                padding-bottom: 0;
            }

            p {
                padding: 0 15px 0 0;
            }

            @include MQ(Lneg) {
                column-count: 1;
                column-gap: normal;
                padding-right: 0;
            }
        }

        .block-body-section-image {
            width: 50%;
            flex-basis: 50%;
            margin-top: -60px;

            @include MQ(Lneg) {
                width: 100%;
                flex-basis: 100%;
                margin-top: 0;
            }

            picture {
                img {
                    width: 100%;
                }
            }
        }

        .block-body-section-box {
            width: 50%;
            flex-basis: 50%;
            padding-bottom: 35px;

            @include MQ(Lneg) {
                width: 100%;
                flex-basis: 100%;
                padding-bottom: 0;
            }

            .box-info-2 {
                padding: 35px;
                margin: 0 15px 15px 0;

                @include MQ(Lneg) {
                    margin: 0 -25px 25px;
                }

                &.box-no-gutter {
                    margin-right: 0;

                    @include MQ(Lneg) {
                        margin-right: -25px;
                    }
                }

                h3 {
                    padding-bottom: 25px;
                }
            }
        }

        .block-body-section-columns {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            @include MQ(Lneg) {
                flex-direction: column;
            }

            .section-column {
                width: col(4);
                flex-basis: col(4);
                padding: 25px;

                @include MQ(Lneg) {
                    width: 100%;
                    flex-basis: 100%;
                }

                .section-column-icon {
                    height: 150px;

                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }

        .box-info {
            h4 {
                text-align: center;
                width: 85%;
                margin: 0 auto;
            }
        }

        .box {
            width: 100%;
            margin-bottom: 45px;

            >* {
                text-align: center;
            }
        }

    }

    .block-body-section-full {
        padding-top: 45px;
        padding-bottom: 25px;
        margin: 0 auto 100px;
        max-width: $max-width;
        @extend %box-fill-default;


        @include MQ(MAXneg) {
            margin: 0 auto 75px;
        }

        @include MQ(XLneg) {
            margin: 0 auto 50px;
        }

        >* {
            max-width: $XL;
            margin: 0 auto;

            @include MQ(XLneg) {
                max-width: $L;
                padding: 0 25px;
            }
        }

        .block-body-sub-title {
            width: 100%;
            flex-basis: 100%;

            h3 {
                padding-bottom: 45px;
            }
        }

        .section-title {
            padding-bottom: 25px;

            p {
                padding-top: 25px;
            }
        }

        .section-split {
            display: flex;
            flex-flow: row wrap;

            .block-body-section-text {
                width: 50%;
                flex-basis: 50%;
                padding-right: 25px;
                padding-bottom: 50px;
                padding-left: 0;


                @include MQ(Lneg) {
                    width: 100%;
                    flex-basis: 100%;
                    padding-right: 0;
                }

                p {
                    padding: 0 15px 0 0;
                    width: 85%;

                    @include MQ(Lneg) {
                        width: 95%;
                    }
                }
            }
        }

    }

    .block-body-section-media {
        padding-top: 45px;
        padding-bottom: 25px;
        margin: 0 auto;
        max-width: $XL;
        overflow: hidden;

        @include MQ(Lneg) {
            padding: 25px;
        }

        .block-body-media {
            padding: 45px 0 0;

            @include MQ(Lneg) {
                overflow-x: auto;
                margin: 0 -25px;
                padding: 45px 25px 25px;
            }

            img {
                width: 100%;
                display: block;
                margin: 0 auto;

                @include MQ(Lneg) {
                    width: auto;
                    min-width: 100vw;
                }
            }

            .media-print-text {
                .block-text__caption {
                    font-style: italic;
                    @include font-size(2);
                }
            }

            .media-print {
                position: relative;

                figcaption {
                    width: 140px;
                    @include MQ(Lneg) {
                        width: auto;
                        position: unset;
                    }

                    a {
                        @extend %print-action;
                    }
                }
            }
        }

        .media-print-text {
            .block-text__caption {
                font-style: italic;
                @include font-size(2);
            }
        }
        .block-body-print {
            width: 184px;
            margin: 0 auto;
            padding-top: 50px;

            a {
                @extend %print-action;
            }
        }

        .block-body-section-info {
            display: flex;

            @include MQ(Lneg) {
                width: $L;
                overflow-x: auto;
            }

            .add_sign {
                padding-top: 30px;
            }

            .info-separator {
                padding: 25px;

                @include MQ(Lneg) {
                    padding: 25px 0 0 25px;
                }

                h4 {
                    text-align: center;
                    padding-bottom: 15px;
                }
            }
        }
    }
}

.block-body-menu {
    padding-top: 25px;

    @include MQ(Lneg) {
        padding: 25px 25px 0;
    }

    .block-menu-box {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin: 0 auto;
        max-width: $XL;
        width: 100%;

        @include MQ(Lneg) {
            flex-direction: column;
        }

        .block-menu-box-inner {     
            &.bw_3 {
                width: 33.33%;
                flex-basis: 33.33%;
                @include MQ(Lneg) {
                    width: 100%;
                    flex-basis: 100%;
                }
            }
            &.bw_5 {
                width: 20%;
                flex-basis: 20%;
                @include MQ(Lneg) {
                    width: 100%;
                    flex-basis: 100%;
                }
            }

            &:last-of-type {
                a {
                    margin-right: 0;
                }
            }

            a {
                margin-right: 25px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                background: $theme-section-green;
                padding: 25px;
                height: 195px;

                transition: .3s all ease;

                &.menu-purple {
                    background: $primary-purple;
                }

                @include MQ(XLneg) {
                    margin-right: 10px;
                }

                @include MQ(Lneg) {
                    height: auto;
                    margin-right: 0;
                    margin-bottom: 25px;
                    flex-direction: row;
                    justify-content: flex-start;
                }

                &:hover {
                    background: $theme-section-primary;
                }

                h2 {
                    padding-bottom: 15px;
                    display: flex;
                    align-items: flex-end;
                    &.block-row-align {
                        display: block;
                    }
                    @include font-size(2.8);

                    @include MQ(MAXneg) {
                        @include font-size(2.8);
                    }

                    @include MQ(XLneg) {
                        // @include fluid-type($L, $XL, 26px, 28px);
                        line-height: 1.4;
                        @include font-size(2.4);
                        @include fluid-type($S, $L, 18px, 24px);
                        line-height: 1.4;
                        padding-right: 25px;
                    }

                    @include MQ(Lneg) {
                        flex-grow: 1;
                        padding-bottom: 0;
                        align-items: center;                        
                    }

                    .block-small-pre-title {
                        font-family: $Barlow;
                        font-weight: $fw-regular;
                        font-size: 1.6rem;
                        line-height: 1;
                        display: block;

                        @include MQ(Lneg) {
                            display: block;
                            padding-bottom: 10px;
                        }
                    }
                    .block-body-menu-small {
                        padding-left: 10px;
                        display: none;
                        font-family: $Barlow;
                        font-weight: $fw-regular;
                        font-size: 1.6rem;
                        line-height: 1;

                        @include MQ(Lneg) {
                            display: block;
                            padding-top: 10px;
                        }
                    }


                    &.menu-box-text-long {
                        @include font-size(2.6);
                        @include fluid-type($L, $max-width, 18px, 26px);
                        line-height: 1.4;
                        padding-bottom: 10px;

                        @include MQ(Lneg) {
                            @include font-size(2.4);
                            @include fluid-type($S, $L, 18px, 24px);
                            line-height: 1.4;
                            padding-right: 25px;
                        }
                    }
                    &.menu-box-text-long-fixed {
                        @include font-size(2.2);
                        @include fluid-type($L, $max-width, 18px, 22px);
                        line-height: 1.2;
                        padding-bottom: 10px;
                    }
                }

                .block-menu-box-trigger {
                    display: flex;
                    align-items: center;

                    .action-link {
                        transition: .3s all ease;
                        padding: 5px 10px 5px 0;

                        @include MQ(XLneg) {
                            @include font-size(1.6);
                            flex-grow: 1;
                        }

                        @include MQ(Lneg) {
                            display: none;
                        }
                    }
                }

                &:hover {
                    .block-menu-box-trigger .action-link {
                        flex-grow: 1;
                    }
                }
            }
        }
    }

    .block-menu-box-fixed {
        background: $theme-color-white;
        padding: 15px 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        box-shadow: 0 0 15px 0 $theme-shadow;
        transform: translateY(-100%);
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        transition: .3s all ease;
        z-index: 1;
        @include MQ(Lneg) {
            display: none;
        }
        &.sticky-visible {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            pointer-events: all;

        }
        .block-menu-box-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            max-width: $XL;
            padding: 0 25px;
        }
        .block-menu-box-inner {
            a {
                display: flex;
                justify-content: center;
                height: auto;
                min-height: 84px;
                max-height: 84px;                
                h2 {
                    padding-bottom: 5px;
                    .block-small-pre-title {
                        display: block;
                    }
                    .block-body-menu-small {
                        display: block;
                    }
                    &.block-heading-wide {
                        padding-top: 5px;
                        @include font-size(2.4);
                        @include fluid-type($XL, $XLS, 21px, 24px);
                    }
                }
            }
        }
        @include MQ(Lneg) {
            display: none;
        }
    }
}