//Testing
// * {
//    outline: 1px solid black;
// }

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 62.5%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    position: relative;
    min-width: $min-width;
    font-size: 2.2rem;
    line-height: 1.6;
    font-family: $Barlow;
    text-rendering: geometricPrecision;
    font-weight: 400;
    color: $theme-color-1;
    overflow-x: hidden;
    min-height: 100vh;
    font-feature-settings: "liga", 0; 
    

    &.loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #fff;
    }

    &.disable-scroll {
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: transparentize($theme-color-1, .5);
            z-index: 10;
        }
    }
}

em {
    font-style: italic;    
    font-weight: inherit;
}

.app-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.disable-scrollbars {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    /* IE 10+ */
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
        /* Chrome/Safari/Webkit */
    }
}

.custom-scrollbars {
    scrollbar-width: thin;
    scrollbar-color: $theme-color-1 $theme-color-white;

    /* IE 10+ */
    &::-webkit-scrollbar {
        width: 12px;
        /* Chrome/Safari/Webkit */
    }

    &::-webkit-scrollbar-track {
        background: $theme-color-white;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $theme-color-1;
        border: 3px solid $theme-color-white;
    }
}

a {
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &[href^="tel:"] {
        text-decoration: none;
    }
}

strong {
    font-weight: 700;
}

button,
.button {
    cursor: pointer;
    transition: opacity .3s ease-out;
}

input:focus {
    outline: none;
}

sup {
    font-size: 57%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -0.5em;
}