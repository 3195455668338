.notifications {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 25px;
    transition: .3s all ease;
    transform: translateY(-100%);
    z-index: 100;
    &.notifications-allowed {
        background: $theme-color-2;
    }
    &.notifications-blocked {
        background: $theme-color-warning;
    }
    .notifications-text {
        color: $theme-color-white;
        font-weight: $fw-semibold;
        font-size: 1.8rem;
        letter-spacing: 0.03em;
    }    
}

nav {        
    display: block;
    padding: 35px 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // background: $theme-navbar;
    background: $theme-color-white;
    z-index: 10;
    @include backdrop-blur(2rem, $theme-color-white);
    @include MQ(XLneg) {
        padding: 35px 15px;
    }
    @include MQ(Lneg) {
        padding: 20px;
    }
    @include MQ(Mneg) {
        padding: 20px 10px 10px;
    }
    .progress-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7px;
        background: transparent;
        z-index: 100;
        .progress-bar {
            height: 100%;
            width: 0;
            background: $theme-progressbar;
            border-radius: 0;
        }
    }

    .blurred-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: $XLS;
        margin: 0 auto;
        @include MQ(Mneg) {
            padding: 0 25px;
        }
        .navbar-brand {
            @include MQ(Lneg) {
                width: col(5);
            }
            .brand {                
                & > a {
                    img {
                        width: 100%;
                        min-width: 200px;
                        max-width: 200px;       
                        @include MQ(XLneg) {
                            min-width: 165px;
                            max-width: 165px;
                        }
                        @include MQ(Lneg) {
                            min-width: 140px;
                            max-width: 140px;
                        }
                    }
                }
            }           
        }
        .navbar-menu {
            margin-bottom: 5px;
            @include MQ(XLneg) {
                margin-bottom: 0;
            }
            @include MQ(Lneg) {
                position: absolute;
                top: 0;
                right: 0;
                background: $theme-navbar-mobile-slideout;
                width: 90vw;
                min-height: 100vh;
                padding: 100px 50px;
                display: flex;
                justify-content: flex-end;
                transform: translateX(100%);
                transition: .3s all ease;
                height: 430px;
                &.navbar-menu-collapse {
                    transform: translateX(0);
                }
            }
            ul {
                display: flex;
                align-items: center;
                @include MQ(Lneg) {
                    align-items: flex-end;
                    flex-direction: column;
                    text-align: right;   
                    height: 100%;
                    overflow-y: auto;                 
                }
                li {
                    &.link-disabled {
                        cursor: not-allowed;
                        opacity: 0.5;
                        a {
                            color: currentColor;
                            display: inline-block;  /* For IE11/ MS Edge bug */
                            pointer-events: none;
                            text-decoration: none;
                        }
                    }
                    a {
                        display: block;
                        color: $theme-navbar-link;
                        font-family: $Anton;
                        padding: 10px 15px;
                        border-bottom: 2px solid transparent;
                        @include font-size(2);
                        @include fluid-type($L, $XLS, 14px, 20px);
                        line-height: 1.4;
                        @include MQ(MAXneg) {
                            padding: 10px 5px;
                        }
                        @include MQ(Lneg) {
                            @include font-size(2);
                            line-height: 1.4;               
                        }
                        &:hover {
                            color: $theme-navbar-link-hover;
                        }
                        &.active-menu {
                            color: $theme-navbar-link-hover;
                            border-bottom-color: $theme-navbar-link-hover;
                            @include MQ(Lneg) {
                                border-bottom-color: transparent;
                            }
                        }
                    }
                }
            }
        }
        .navbar-alert {
            @include MQ(Lneg) {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            .menu-small {
                display: none;
                @include MQ(Lneg) {
                    display: block;
                    flex: 1 1 auto;
                    padding-left: 25px;
                    .hamburger:hover {
                        opacity: 1;
                    }
                    .hamburger-inner {
                        background: $theme-navbar-link;
                        &:before, &:after {
                            background: $theme-navbar-link;
                        }
                    }
                }
            }
            .push-alert {
                .push-alert-icon {
                    position: relative;
                    display: inline-block;
                    cursor: pointer;

                    svg.push-badge {
                        .bell {
                            @extend %push-badge;
                        }
                        &.warning-badge {
                            .bell {
                                @extend %push-badge-warning;
                            }
                            &:hover {
                                @extend %push-badge-warning;
                            }
                        }
                        &.active-badge {
                            .bell {
                                @extend %push-badge-active;
                            }
                        }
                        &.badge-notification-pop {
                            .bell {
                                @extend %push-badge-default;
                            }
                        }
                    }
                    // &:hover {
                    //     svg.push-badge {
                    //         .bell {
                    //             @extend %push-badge-active;
                    //         }
                    //     }
                    // }
                }
            }
        }
    }
}