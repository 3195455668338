.box-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    width: 100%;
    background: $theme-color-3;
    margin-bottom: 15px;
    .placeholder-text {
        flex-grow: 1;
        display: block;
        padding: 0 10px;
        width: auto;
        height: 24px;
        background: $theme-color-1;
        margin-right: 15px;
        animation: placeholder-blink 1s infinite ease-in-out;
    }
    .placeholder-icon {
        display: block;
        margin-left: auto;
        padding: 0 10px;
        width: 100px;
        height: 24px;
        background: $primary-purple;
        animation: placeholder-blink 1s infinite ease-in-out;
    }
}