br.u-md-break {
    @include MQ(XLneg) {
        display: none;
    }
}

.block-heading {
    &--default {
        @include font-size(4);   
        @include fluid-type($XL, $XLS, 28px, 40px);
        @include MQ(MAXneg) {
            line-height: 36px;
        }
    }
    &--media-caption {
        @include font-size(3);   
        @include fluid-type($max-width, $XLS, 18px, 30px);
        @include MQ(MAXneg) {
            line-height: 1.3;
            @include fluid-type($XL, $max-width, 22px, 30px);
        }
        @include MQ(XLneg) {
            @include font-size(3);
        }
        @include MQ(Lneg) {
            @include font-size(3.6);
        }
        @include MQ(SMneg) {
            @include font-size(2.6);
        }
        @include MQ(Sneg) {
            @include font-size(2.2);
        }
    }
    &--box-item-caption {
        font-family: $Anton;
        @include font-size(2.8);   
        @include MQ(MAXneg) {
            line-height: 1.2;
            font-size: 2.4rem;
            @include fluid-type($XL, $max-width, 22px, 24px);
        }
    }
    &--default-white {        
        color: $theme-color-white;            
    }
    &--default-primary {
        color:  lighten($theme-color-1, 15%);
    }
    &--primary-green {
        color:  $primary-green;
    }
    &--primary-purple {
        color:  $primary-purple;
    }
    &--default-secondary {
        color:  $theme-color-2;
    }
    &--form-title {
        @include heading-font-size(4);
        @include fluid-type($XL, $XLS, 30px, 40px);
        color: $primary-purple;
        @include MQ(Lneg) {
            line-height: 1.4;
            font-size: 3rem;
        }
    }
    &--form-title-long {
        @include heading-font-size(4);
        @include fluid-type($XL, $XLS, 30px, 40px);
        color: $theme-form-header;
        @include MQ(Lneg) {
            line-height: 1.4;
            font-size: 4rem;
        }
    }
    &--page-title {
        @include heading-font-size(6);
        @include fluid-type($XL, $XLS, 40px, 60px);
        color: lighten($theme-header-color, 15%);
        @include MQ(Lneg) {
            @include font-size(4.4);
        }
    }
    &--page-title-large {
        @include heading-font-size(6);
        @include fluid-type($XL, $XLS, 40px, 60px);
        @include MQ(Lneg) {
            @include font-size(4.4);
        }
    }
    &--page-sub-title-2x {
        @include heading-font-size(3.4);
        @include fluid-type($XL, $XLS, 28px, 34px);
        @include MQ(Lneg) {
            @include font-size(3);
        }
    }
    &--page-sub-title {
        @include heading-font-size(3.6);
        @include fluid-type($XL, $XLS, 30px, 36px);
        @include MQ(Lneg) {
            @include font-size(3.2);
        }
    }
    &--box-info-title {
        @include heading-font-size(2.8);
        @include fluid-type($XL, $XLS, 22px, 28px);
        @include MQ(Lneg) {
            @include font-size(2.8);
        }
    }
    &--box-info-sub-title {
        @include heading-font-size(2.6);
        @include fluid-type($XL, $XLS, 20px, 26px);
        @include MQ(Lneg) {
            @include font-size(2.2);
        }
    }
    &--page-caption {
        font-family: $Anton;
        font-weight: $fw-regular;
        @include font-size(4);
        @include fluid-type($XL, $XLS, 30px, 40px);
    }
    &--page-caption-green {
        color: $primary-green;
    }
    &--page-caption-purple {
        color: $primary-purple;
    }
    &--small-caption {
        font-family: $Anton;
        margin-top: 10px;
        display: block;
        @include font-size(2.4);
        @include fluid-type($XL, $XLS, 20px, 24px);
    }
} 

.block-text {
    &__default {
        @include font-size(2.4);
        @include fluid-type($XL, $XLS, 20px, 24px);
    }
    &--small-white {
        @include font-size(2);
        @include fluid-type($XL, $XLS, 16px, 20px);
        color: $theme-color-white;
    }
    &--card-link-white {
        @include font-size(1.8);
        color: $theme-color-white;
        font-weight: $fw-semibold;        
    }
    &--bold {
        font-weight: $fw-bold;
    }
    &--medium {
        font-weight: $fw-semibold;
    }
    &--footer-small {
        @include font-size(1.4);
        a {
            color: $theme-color-1;
        }
    }
    &--media-subtext {
        @include font-size(2.6);
        color: $theme-section-green;
        font-weight: $fw-semibold;
        letter-spacing: 0.03em;
    }
    &--card-link-media-text {
        @include font-size(2.4);
        color: $theme-color-white;
        font-weight: $fw-semibold;
        @include MQ(MAXneg) {
            @include font-size(2.2);    
        }
        @include MQ(XLneg) {
            @include font-size(2.4);    
        }
    }
    &--regular {
        font-size: 2.4rem;
        color: $theme-section-primary;
        font-weight: $fw-regular;   
        padding: 25px 0;
        line-height: 1.6;
        @include fluid-type($XL, $XLS, 18px, 24px);
        @include MQ(Lneg) {
            @include font-size(2.4);
        }
    }
    &--align-center {
        text-align: center;
    }
}


.text-dark {
    color: $theme-color-1;
}