.clear-spacer {
    .aside-block {
        .download-block {
            background: $theme-color-white;
            padding: 25px;

            &.sticky {
                position: fixed;
                z-index: 10;
            }

            @include MQ(Lneg) {
                position: relative !important;
                top: unset !important;
                width: 100% !important;
            }
        }

        .download-block-heading {
            padding-bottom: 25px;
        }

        .download-block-list {
            height: calc(100vh - 400px);
            max-height: 450px;
            overflow-y: auto;        
            .download-block-list-item {
                &:last-of-type {
                    .download-item {
                        margin-bottom: 0;
                    }
                }
            }    

            .download-item {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                padding: 10px 35px;
                background: $theme-color-3;
                width: 100%;
                min-height: 75px;
                transition: .3s all ease;

                @include MQ(MAXneg) {
                    text-align: left;
                }

                &:hover {
                    background: $primary-purple;
                    h3 {
                        color: $theme-color-white;
                    }
                    .download-small {
                        color: $theme-color-white;
                        svg {
                            .arrow-chevron-bottom,
                            .arrow-leading-line {
                                stroke: $theme-color-white;
                            }
                        }
                    }
                }

                h3 {
                    display: flex;
                    align-items: center;
                    width: calc(100% - 120px);
                    font-size: 2.2rem;
                    // font-family: $Barlow;
                    font-family: $Anton;
                    font-weight: $fw-semibold;
                    flex-grow: 1;
                    transition: .3s all ease;
                    letter-spacing: 0.03em;

                    @include MQ(MAXneg) {
                        font-size: 2.4rem;
                    }
                }

                .download-small {
                    width: 120px;
                    font-size: 1.8rem;
                    font-weight: $fw-bold;
                    color: $primary-purple;
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    padding: 10px 15px;
                    border-radius: 3px;

                    @include MQ(MAXneg) {
                        padding-top: 15px;
                    }

                    svg {
                        margin-left: 10px;
                        .arrow-chevron-bottom,
                        .arrow-leading-line {
                            fill: none;
                            stroke: $primary-purple;
                            stroke-linecap: round;
                            stroke-width: 2px;
                        }

                        .arrow-chevron-bottom {
                            stroke-miterlimit: 10;
                        }
                    }
                }
            }
        }
    }
}