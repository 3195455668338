footer {
    margin: 0 auto;
    width: 100%;
    max-width: $XLS;
    @include MQ(XLSneg) {
        padding: 0 25px;
    }
    .block-footer-container {
        padding: 35px 0;
        border-top: 1px solid $theme-divider-color-primary;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include MQ(Lneg) {
            flex-direction: column;
        }

        .block-footer-container-column {
            @include MQ(Lneg) {
                padding-bottom: 10px;
            }
            a {
                &:hover {
                    color: $theme-navbar-link-hover;
                }
            }
            &.block-footer-container-column--copyright {
                p {
                    text-align: left;
                }
            }
            &.block-footer-container-column--privacy-policy {
                p {
                    text-align: center;
                }
            }
            &.block-footer-container-column--author {
                p {
                    text-align: right;
                }
            }
        }
    }
}