$webinar-button-size: 92px;
$webinar-button-radius: $webinar-button-size / 2;
$webinar-button-icon-size: $webinar-button-radius / 1.2;

.content-block-media {
    padding: 25px 0 75px;
    height: 100%;

    @include MQ(XLSneg) {
        padding: 25px 25px 75px;
    }

    @include MQ(Lneg) {
        padding: 25px 0 75px;
    }

    .content-block-media-list {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-flow: row nowrap;
        width: col(12);

        @include MQ(XLneg) {
            flex-direction: column;
        }

        .content-block-media-text {
            @include MQ(Lneg) {
                padding: 0 25px 5px;

                p {
                    @include font-size(2.5);
                }
            }
            @include MQ(SMneg) {
                padding: 0 25px 10px;

                p {
                    @include font-size(2);
                }
            }
        }
        
        .content-block-media-item-featured-static {
            width: col(6);
            position: relative;
            @include MQ(XLneg) {
                width: col(12);
            }
        }

        .content-block-media-item-featured {
            padding-right: 25px;
            width: col(12);

            @include MQ(XLneg) {
                width: 100%;
                padding-right: 0;
            }

            .content-block-media-latest {
                padding-top: 10px;

                a.placeholder-item:hover {
                    cursor: default;
                }

                a:hover {
                    figure .block-media-image img {
                        transform: scale(1.1);
                        @include MQ(Lneg) {
                            transform: none;
                        }
                    }
                }

                a.placeholder-item {
                    figure {
                        .block-media-image {
                            picture {
                                .placeholder-item-image {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    background: $theme-color-1;
                                }
                            }                            
                        }
                    }
                }

                figure {
                    font-size: 0;
                    position: relative;

                    .block-media-image {
                        position: relative;
                        height: 489px;
                        overflow: hidden;

                        @include MQ(Lneg) {
                            height: auto;
                        }

                        &:after {
                            content: '';
                            display: block;
                        }

                        picture {
                            width: 100%;
                            height: 100%;

                            @include MQ(Lneg) {
                                position: relative;
                                top: 0;
                                transform: none;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                transition: .3s;
                                object-fit: cover;
                            }
                        }

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: transparentize($theme-section-primary, .6);
                        }
                    }

                    .block-media-text {
                        position: absolute;
                        bottom: 35px;
                        left: 35px;

                        @include MQ(Lneg) {
                            position: relative;
                            bottom: 0;
                            left: 0;
                            padding: 25px;
                        }

                        h2 {
                            @include MQ(Lneg) {
                                @include font-size(5);
                                @include fluid-type($S, $L, 40px, 50px);
                            }
                            @include MQ(Sneg) {
                                @include font-size(3.6);
                            }
                        }

                        p {
                            padding: 15px 0 0;

                            @include MQ(Lneg) {
                                @include fluid-type($S, $L, 22px, 30px);
                            }

                            span {
                                display: none;
                            }
                        }
                    }

                }

                .block-media-action {
                    @include center;
                    z-index: 1;

                    .action-box {
                        background: $theme-section-green;
                        border-radius: $webinar-button-radius;
                        width: $webinar-button-size;
                        height: $webinar-button-size;
                        display: block;
                        position: relative;
                        transform: scale(1.15);

                        @include MQ(Lneg) {
                            transform: scale(1);
                        }
                        @include MQ(Sneg) {
                            transform: scale(.7);
                        }

                        .action-box-inner {
                            @include center;
                            left: 50px;
                            display: inline-block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 21.5px 0 21.5px 37.2px;
                            border-color: transparent transparent transparent $theme-color-white;
                        }
                    }
                }
            }
        }

        .content-block-media-items {
            flex: 1 1 auto;
            width: col(6);

            @include MQ(XLneg) {
                width: 100%;
                padding-top: 35px;
            }
        }

        .content-block-media-thumbs {
            margin-top: 10px;
            position: relative;

            @include MQ(Lneg) {
                padding: 10px 25px;
                height: auto;
                overflow: unset;
            }

            .media-thumb-item {
                height: 240px;
                margin-bottom: 10px;
                &:last-of-type {
                    margin-bottom: 0;
                }

                @include MQ(Lneg) {
                    height: auto;
                }

                a.placeholder-item:hover {
                    cursor: default;
                }

                a:hover {
                    figure .block-media-image img {
                        transform: scale(1.1);
                        @include MQ(Lneg) {
                            transform: none;
                        }
                    }

                    .block-media-text {

                        h2,
                        p {
                            color: $primary-purple;
                        }
                    }
                }

                a.placeholder-item {
                    figure {
                        .block-media-image {
                            .placeholder-item-image {
                                display: block;
                                width: 100%;
                                height: 100%;
                                background: $theme-color-1;
                            }
                        }
                    }
                }

                figure {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    @include MQ(Lneg) {
                        display: block;
                    }

                    .block-media-image {
                        position: relative;
                        width: 360px;
                        height: 100%;
                        overflow: hidden;

                        @include MQ(Lneg) {
                            width: 100%;                            
                            // height: auto;
                            height: 489px;
                        }

                        &:after {
                            content: '';
                            display: block;
                            // padding-bottom: 56.25%;

                            @include MQ(Lneg) {
                                display: none;
                            }
                        }

                        picture {
                            width: 100%;
                            height: 100%;
                            // position: absolute;
                            // width: 100%;
                            // top: 50%;
                            // left: 0;
                            // transform: translateY(-50%);

                            @include MQ(Lneg) {
                                position: relative;
                                top: 0;
                                transform: none;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                transition: .3s;
                                object-fit: cover;

                                @include MQ(Lneg) {
                                    width: 100%;
                                }
                            }
                        }

                        .block-media-action {
                            @include center;

                            .action-box {
                                background: $primary-purple;
                                border-radius: $webinar-button-radius;
                                width: $webinar-button-size;
                                height: $webinar-button-size;
                                display: block;
                                position: relative;
                                transform: scale(.75);

                                @include MQ(Sneg) {
                                    transform: scale(.5);
                                }

                                .action-box-inner {
                                    @include center;
                                    left: 50px;
                                    display: inline-block;
                                    width: 0;
                                    height: 0;
                                    border-style: solid;
                                    border-width: 21.5px 0 21.5px 37.2px;
                                    border-color: transparent transparent transparent $theme-color-white;
                                }
                            }
                        }
                    }

                    .block-media-text {
                        padding: 0 25px;

                        @include MQ(Lneg) {
                            padding: 25px 0;
                        }

                        h2,
                        p {
                            transition: .3s all ease;
                        }
                    }

                    .block-media-text {
                        width: 50%;
                        flex-basis: 50%;

                        @include MQ(Lneg) {
                            width: 100%;
                            flex-basis: unset;
                        }
                    }
                }

            }

        }
    }

}