.block-body-section-text {
    &.pb-zero {
        @include MQ(Lneg) {
            padding-bottom: 0 !important;
        }
    }
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    } 
}