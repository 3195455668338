.qa-collection-wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 50px 25px;
    z-index: 1;
    position: relative;
    .qa-title-block {
        padding-left: 25px;
        padding-bottom: 70px;
        @include MQ(Lneg) {
            padding-bottom: 35px;
        }
        h2 {
            @include MQ(Lneg) {
                @include font-size(2.4);
            }
        }
    }

    .qa-collection-list {
        .qa-collection-list-item {
            width: 100%;
            max-width: $L;
            margin-bottom: 25px;   
            &.qa-expanded {                
                .qa-collection-tab-panel-title {
                    svg {
                        transform: rotate(-180deg);
                    }
                }
            }
            
            &:nth-child(odd) {
                .qa-collection-tab-panel-title {
                    background: $theme-qa-question-primary;
                    &:hover {
                        background: darken($theme-qa-question-primary, 3%);
                    }    
                    .panel-pre-header,
                    .panel-header {
                        color: $primary-purple;
                    }
                    svg {
                        g {
                            .arrow {
                                stroke: $primary-purple;
                            }
                        }
                    }
                }
            }
            &:nth-child(even) {
                .qa-collection-tab-panel-title {
                    background: $theme-qa-question-secondary;
                    &:hover {
                        background: darken($theme-qa-question-secondary, 3%);
                    }    
                    .panel-pre-header,
                    .panel-header {
                        color: $primary-green;
                    }
                    svg {
                        g {
                            .arrow {
                                stroke: $primary-green;
                            }
                        }
                    }
                }
            }
            
            @include MQ(XLneg) {
                max-width: 100%;
            }
        }

        .qa-collection-tab-panel-title {
            padding: 35px 50px;       
            transition: .3s all ease;
            cursor: pointer;    
            
            @include MQ(Lneg) {
                padding: 25px;
            }

            .panel-pre-header {
                font-family: $Anton;
                @include font-size(2.4);
                @include fluid-type($XL, $XLS, 20px, 24px);                
            }

            .panel-header-wrapper {
                display: flex;
                align-items: flex-start;           
                position: relative;
                width: col(12);
                .panel-header {
                    width: col(9);
                    text-transform: uppercase;
                    font-weight: $fw-bold;
                    padding: 10px 0;
                    @include font-size(3);
                    @include fluid-type($XL, $XLS, 24px, 30px);                
                    line-height: 1.2;
                }
                .panel-header-icon {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    width: col(3);
                    padding-left: 25px;
                    padding-top: 10px;
                    @include MQ(Lneg) {
                        position: absolute;
                        bottom: 100%;
                        right: 0;
                        width: col(12);
                    }
                    .panel-text {
                        color: $dark-navy;                        
                        padding-right: 15px;
                        font-weight: $fw-bold;
                        text-transform: uppercase;
                        @include font-size(1.6);
                        @include fluid-type($XL, $XLS, 16px, 16px);
                        @include MQ(SMneg) {
                            display: none;
                        }
                    }
                    svg {
                        transition: .3s all ease;
                        g {
                            .arrow {
                                fill:none;
                                stroke-width:2;
                                stroke-linecap:round; 
                            }
                        }
                    }
                }
            }
            .panel-header-question-author {
                text-transform: uppercase;
                color: $dark-navy;
                font-weight: $fw-bold;
                @include font-size(1.6);
                @include fluid-type($XL, $XLS, 16px, 16px);
            }
        }

        .qa-collection-tab-panel-content {
            background: $theme-color-3;
            padding: 25px 45px;
            display: none;
            @include MQ(Lneg) {
                padding: 25px;
            }
            &.qa-panel-show {
                display: block;
            }
            .panel-content-pre-header {
                color: $dark-navy;
                font-family: $Anton;
                @include font-size(2.4);
                @include fluid-type($XL, $XLS, 20px, 24px);
            }
            p:not(.panel-content-pre-header):not(.panel-content-respond-author) {
                @include font-size(2.4);
                @include fluid-type($XL, $XLS, 20px, 24px);
                padding: 10px 5vw 10px 0;
                color: $dark-navy;
                @include MQ(Lneg) {
                    padding: 25px 0;
                }
            }
            .panel-content-respond-author {
                @include font-size(1.6);
                @include fluid-type($XL, $XLS, 16px, 16px);
                text-transform: uppercase;
                color: $dark-navy;
                font-weight: $fw-bold;
                padding: 25px 0;
            }
        }
    }
}

.qa-container-image {
    position: absolute;
    top: -50px;
    right: 175px;
    height: 100%;
    width: 580px;
    z-index: 0;
    @include MQ(XLSneg) {
        right: 0;
    }
    @include MQ(XLneg) {
        position: relative;
        top: 0;
        margin-bottom: 35px;
        width: 100%;
    }
    .qa-image-block {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        @include MQ(XLneg) {
            width: 100%;
            position: relative;
            top: 0;
        }
        img {
            width: 100%;
        }
    }
}