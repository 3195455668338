.container-columns {
    display: flex;
    margin: 0 auto;
    padding: 0 0 0 75px;
    max-width: $XXL;
    @include MQ(XLSneg) {
        padding: 0 25px;
    }
    @include MQ(XLneg) {
        flex-direction: column;
    }
    @include MQ(Lneg) {
        flex-direction: column;
        padding: 0 25px;
    }

    .grid-columns {
        width: 100%;
        max-width: calc(400px * 2 + 25px);        
        @include MQ(XLneg) {
            order: 2;
            max-width: 100%;
        }
    }
    .grid-columns-single {
        padding-left: 35px;
        width: 100%;
        max-width: 600px;
        @include MQ(MAXneg) {
            max-width: col(5);
        }
        @include MQ(XLneg) {
            order: 1;
            max-width: 100%;
        }
        @include MQ(Lneg) {
            padding-left: 0;
            padding-top: 25px;
        }
    }

    .grid-columns {
        display: flex;
        flex-flow: row wrap;

        padding: 25px 0;

        @include MQ(XLneg) {
            justify-content: space-between;
        }
        @include MQ(Lneg) {
            flex-direction: column;
        }
        
        .item-link-card {
            margin-bottom: 25px;            
            width: 100%;
            max-width: 389px;
            height: 100%;
            max-height: 250px;
            @include MQ(XLSneg) {
                max-width: calc(389px - 45px);
                max-height: calc(250px - 45px);
                margin-bottom: 45px;
            }
            @include MQ(MAXneg) {
                max-width: calc(389px - 75px);
                max-height: calc(250px - 75px);
                margin-bottom: 55px;
            }
            @include MQ(XLneg) {
                max-width: 49%;
                max-height: 100%;                
                margin-bottom: 20px;
            }
            @include MQ(Lneg) {
                max-width: 100%;
            }
            &:nth-child(odd) {
                margin-right: 25px;
                margin-top: -100px;
                @include MQ(XLneg) {
                    margin-top: 0;
                    margin-right: 0;
                }
            }
            figure {
                position: relative;
                width: 100%;
                height: 100%;    
                @include MQ(XLneg) {
                    font-size: 0;
                }
                &.card-disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                    a {
                        display: inline-block;  /* For IE11/ MS Edge bug */
                        pointer-events: none;
                        text-decoration: none;
                    }
                }
                a.item-link-card-anchor {
                    display: inline-block;
                    @include MQ(XLneg) {
                        width: 100%;
                    }
                    &:hover {
                        .item-link-card-banner .item-link-card-banner-inner img {
                            transform: scale(1.25);
                        }
                        .item-link-card-info .item-link-card-info-action .action-link {
                            flex-grow: 1;
                        }
                    }
                }

                .item-link-card-banner {
                    position: relative;
                    width:100%;
                    height: 100%;
                    @include MQ(XLSneg) {
                        max-width: 100%;
                        max-height: 100%;
                    }
                    .item-link-card-banner-inner {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                        z-index: 0;
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 1;
                            opacity: .2;
                            background:$theme-color-1;
                        }
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: relative;
                            @include MQ(XLSneg) {
                                max-width: 100%;
                                max-height: 100%;
                            }
                            transition: .3s all ease;
                        }
                    }

                }

                .item-link-card-info {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    padding: 0 25px 25px 25px;

                    @include MQ(XLSneg) {
                        padding: 0 25px 0;
                    }

                    @include MQ(XLSneg) {
                        padding: 0 25px 25px 25px;
                    }

                    .item-link-card-info-title {
                        padding-bottom: 10px;
                        h3 {                        
                            @include MQ(Lneg) {
                                @include font-size(4);
                            }
                            @include MQ(Mneg) {
                                @include font-size(2.8);
                            }
                        }
                    }

                    .item-link-card-info-action {
                        display: flex;
                        align-items: center;
                        .action-link {
                            padding-right: 10px;
                            transition: .3s all ease;
                        }
                    }
                }
            }
        }
    }

    .grid-columns-single {
        .column-block {
            padding: 0 25px;
            @include MQ(Lneg) {
                padding: 35px 0 0;
            }
            .column-divider {
                display: block;
                padding: 150px 0 125px;
                @include MQ(XLneg) {
                    padding: 25px 0;
                }
                @include MQ(Lneg) {
                    display: none;
                }
                
                .column-divider-line {
                    display: block;
                    width: col(12);
                    height: 2px;
                    // background: $theme-divider-color-primary;
                    background: $primary-green;
                }
            }

            .column-context {
                h3 {
                    padding-bottom: 25px;
                }
            }
        }
    }
}