$font-path: "fonts";

@font-face {
    font-family: 'Barlow';
    src: url('#{$font-path}/barlow/barlow-light.eot'); /* IE9 Compat Modes*/
    src: local('Barlow Light'), local('Barlow-Light'),
         url('#{$font-path}/barlow/barlow-light.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/barlow/barlow-light.woff') format('woff'), /* Pretty Modern Browsers */
         url('#{$font-path}/barlow/barlow-light.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/barlow/barlow-light.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/barlow/barlow-light.svg#Barlow-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow';
    src: url('#{$font-path}/barlow/barlow-regular.eot'); /* IE9 Compat Modes*/        
    src: local('Barlow Regular'), local('Barlow-Regular'),
         url('#{$font-path}/barlow/barlow-regular.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/barlow/barlow-regular.woff') format('woff'), /* Pretty Modern Browsers */
         url('#{$font-path}/barlow/barlow-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/barlow/barlow-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/barlow/barlow-regular.svg#Barlow-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow';
    src: url('#{$font-path}/barlow/barlow-italic.eot'); /* IE9 Compat Modes*/        
    src: local('Barlow Italic'), local('Barlow-Italic'),
         url('#{$font-path}/barlow/barlow-italic.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/barlow/barlow-italic.woff') format('woff'), /* Pretty Modern Browsers */
         url('#{$font-path}/barlow/barlow-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/barlow/barlow-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/barlow/barlow-italic.svg#Barlow-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow';
    src: url('#{$font-path}/barlow/barlow-medium.eot'); /* IE9 Compat Modes*/
    src: local('Barlow Medium'), local('Barlow-Medium'),
         url('#{$font-path}/barlow/barlow-medium.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/barlow/barlow-medium.woff') format('woff'), /* Pretty Modern Browsers */
         url('#{$font-path}/barlow/barlow-medium.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/barlow/barlow-medium.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/barlow/barlow-medium.svg#Barlow-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow';
    src: url('#{$font-path}/barlow/barlow-medium-italic.eot'); /* IE9 Compat Modes*/
    src: local('Barlow Medium Italic'), local('Barlow-Medium-Italic'),
         url('#{$font-path}/barlow/barlow-medium-italic.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/barlow/barlow-medium-italic.woff') format('woff'), /* Pretty Modern Browsers */
         url('#{$font-path}/barlow/barlow-medium-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/barlow/barlow-medium-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/barlow/barlow-medium-italic.svg#Barlow-Medium-Italic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Barlow';
    src: url('#{$font-path}/barlow/barlow-bold.eot'); /* IE9 Compat Modes*/
    src: local('Barlow Bold'), local('Barlow-Bold'),
         url('#{$font-path}/barlow/barlow-bold.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('#{$font-path}/barlow/barlow-bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('#{$font-path}/barlow/barlow-bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('#{$font-path}/barlow/barlow-bold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('#{$font-path}/barlow/barlow-bold.svg#Barlow-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
// @font-face {
//     font-family: 'Anton';
//     src: url('#{$font-path}/anton/anton-regular.eot'); /* IE9 Compat Modes*/
//     src: url('#{$font-path}/anton/anton-regular.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
//          url('#{$font-path}/anton/anton-regular.woff') format('woff'), /* Pretty Modern Browsers */
//          url('#{$font-path}/anton/anton-regular.woff2') format('woff2'), /* Super Modern Browsers */
//          url('#{$font-path}/anton/anton-regular.ttf') format('truetype'); /* Safari, Android, iOS */
//     font-weight: 400;
// }

@font-face {
    font-family: 'Anton';
    src: url('#{$font-path}/anton/anton-regular.eot');
    src: local('Anton Regular'), local('Anton-Regular'),
         url('#{$font-path}/anton/anton-regular.eot?#iefix') format('embedded-opentype'),
         url('#{$font-path}/anton/anton-regular.woff2') format('woff2'),
         url('#{$font-path}/anton/anton-regular.woff') format('woff'),
         url('#{$font-path}/anton/anton-regular.ttf') format('truetype'),
         url('#{$font-path}/anton/anton-regular.svg#Anton-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//   font-family: 'FontAwesome';
//   src: url('#{$font-path}/fontawesome/fontawesome-webfont.eot?v=3.0.1');
//   src: url('#{$font-path}/fontawesome/fontawesome-webfont.eot?#iefix&v=3.0.1') format('embedded-opentype'),
//     url('#{$font-path}/fontawesome/fontawesome-webfont.woff?v=3.0.1') format('woff'),
//     url('#{$font-path}/fontawesome/fontawesome-webfont.ttf?v=3.0.1') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }